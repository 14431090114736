


































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { FileDownload } from '@/types/cms/components/';
import { DataTableHeader } from 'vuetify/types/';
import { getFileObjectByUrl } from '@/utils/filehelper';

/**
 * CMSAdminComponentFileDownloadFile component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-admin-component-file-download-form-dialog': () => import(
            './CMSAdminComponentFileDownloadFormDialog.vue'
        )
    }
})
export default class CMSAdminComponentFileDownloadFile extends Vue {
    // Prop of type boolean that defined whether the items are editable or not
    @Prop(Boolean) readonly editable: boolean | undefined;

    // array with all files
    @Prop({ type: Array as () => Array<FileDownload> }) readonly files: Array<FileDownload> | undefined;

    // search text
    private searchText = '';

    // the item of the new/edited file
    private editedFileItem: FileDownload = {
        id: -1,
        name: '',
        fileObj: undefined,
        fileUrl: '',
        icon: 'mdi-file-pdf-outline'
    };

    // Default file item values
    private defaultFileItem: FileDownload = {
        id: -1,
        name: '',
        fileObj: undefined,
        fileUrl: '',
        icon: 'mdi-file-pdf-outline'
    }

    // The index of the edited file (-1 = new file)
    private editedFileIndex = -1;

    // Boolean that defines, whether the FormDialog will be displayed or not
    private showFormDialog = false;

    // Mode of the current form (CRUD string)
    private formDialogMode = 'create';

    /**
     * @returns Array with all table headers
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get tableHeaders(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            {
                text: this.$t('table.headers.id').toString(),
                value: 'id'
            },
            {
                text: this.$t('table.headers.name').toString(),
                value: 'name'
            },
            {
                text: this.$t('table.headers.fileUrl').toString(),
                value: 'fileUrl'
            },
            {
                text: this.$t('table.headers.icon').toString(),
                value: 'icon'
            }
        ];

        if (this.editable) {
            headers.push({
                text: this.$t('table.headers.actions').toString(),
                value: 'actions'
            });
        }
        
        return headers;
    }

    /**
     * Opens the FileFormDialog to create a new file
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private addFile() {
        // get max id
        const id = (this.files && this.files.length >= 1) ? this.files[this.files.length -1].id +1 : 0;

        this.editedFileItem = {...this.defaultFileItem, id: id };
        this.editedFileIndex = -1;
        this.formDialogMode = 'create';
        this.showFormDialog = true;
    }

    /**
     * Opens the FileFormDialog to edit the given file element
     * 
     * @param fileItem file item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async editFile(fileItem: FileDownload) {
        if (this.files && fileItem.fileUrl) {
            const fileUrl = process.env.VUE_APP_API_URL + fileItem.fileUrl;
            const fileObj = await getFileObjectByUrl(fileUrl, fileItem.name);

            this.editedFileItem = { ...fileItem, fileObj: fileObj };
            this.editedFileIndex = this.files.indexOf(fileItem);
            this.formDialogMode = 'update';
            this.showFormDialog = true;
        }
    }

    /**
     * Deletes the given file
     * 
     * @param fileItem file item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async deleteFile(fileItem: FileDownload) {
        if (this.files && fileItem.fileUrl) {
            const fileUrl = process.env.VUE_APP_API_URL + fileItem.fileUrl;
            const fileObj = await getFileObjectByUrl(fileUrl, fileItem.name);

            this.editedFileItem = { ...fileItem, fileObj: fileObj };
            this.editedFileIndex = this.files.indexOf(fileItem);
            this.formDialogMode = 'delete';
            this.showFormDialog = true;
        }
    }

    /**
     * Updates the edited item in the data table
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private updateEditedFile(formDialogMode: string, fileItemIndex: number, fileItem: FileDownload) {
        if (this.files) {
            if (formDialogMode == 'create') {
                this.files.push(fileItem);
            }
            else if (formDialogMode == 'update') {
                Object.assign(this.files[fileItemIndex], fileItem);
            }
            else if (formDialogMode == 'delete') {
                this.files.splice(fileItemIndex, 1);
            }

            this.showFormDialog = false;
        }
    }
}
